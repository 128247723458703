import Storage from "@components/StorageTTL";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { AdditionalCustomersType } from "@types/AdditionalCustomersType";
import { CustomerByUserTypeEdited } from "./useCustomersById";

export function useAdditionalCustomers() {
  const { cart, loading: loadingCart } = useShoppingCart();
  const storageKey = `${cart?.id}:additional-customers`;

  async function updateAdditionalCustomers(customers: AdditionalCustomersType[], cartId?: string) {
    try {
      await Storage.setItem(cartId || storageKey, customers);
    } catch (error) {
      console.log(error);
    }
  }

  async function getAdditionalCustomers(cartId?: string): Promise<AdditionalCustomersType[]> {
    try {
      const value: AdditionalCustomersType[] = await Storage.getItem(`${cartId}:additional-customers`);
      return value ?? [];
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async function generateCheckedCustomers(cartId: string, customers: CustomerByUserTypeEdited[]): Promise<CustomerByUserTypeEdited[]> {
    const selectedCustomers = await Storage.getItem(`${cartId}:additional-customers`);

    customers.map((item) => {
      const customer = selectedCustomers?.find((customer: AdditionalCustomersType) => customer.code == item.code);

      if (customer) {
        return item.isChecked = true;
      }

      if (item.isChecked != undefined) {
        item.isChecked = false
      };

    });

    return customers;
  }

  async function includeAdditionalCustomers(cartId: string, customers: AdditionalCustomersType[]) {
    let storageKey = `${cartId}:additional-customers`;
    const selectedCustomers: AdditionalCustomersType[] = await Storage.getItem(storageKey);
    let customersToAdd = customers.filter(item => !selectedCustomers?.map(item => item.code).includes(item.code));

    if (!selectedCustomers) {
      return updateAdditionalCustomers(customersToAdd, storageKey);
    }

    updateAdditionalCustomers([...selectedCustomers, ...customersToAdd], storageKey);
  }

  async function removeAdditionalCustomers(cartId: string, customers: AdditionalCustomersType[]) {
    let storageKey = `${cartId}:additional-customers`;

    const selectedCustomers: AdditionalCustomersType[] = await Storage.getItem(storageKey);
    const newSelectedCustomers = selectedCustomers?.filter(item => !customers.map(item => item.code).includes(item.code));

    updateAdditionalCustomers(newSelectedCustomers, storageKey);
  }

  return {
    loading: loadingCart,
    updateAdditionalCustomers,
    getAdditionalCustomers,
    generateCheckedCustomers,
    includeAdditionalCustomers,
    removeAdditionalCustomers
  }
};
