import React, { useState } from 'react';
import { Image, TextInput } from 'react-native';
import { View, Text, Center } from 'native-base';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { IconButton } from 'react-native-paper';
import { RFValue } from 'react-native-responsive-fontsize';
import Ionicons from 'react-native-vector-icons/Ionicons';

import { PopoverMessageValidation } from '@components/PopoverMessageValidation';
import helpers from '@helpers/NumberHelper';
import { useShoppingCart } from '@hooks/useShoppingCart';
import { ItemType } from '@types/CartType';

import { styles } from './styles';
import { useAuth } from '@contexts/Auth';
import { Config } from '@configs/Config';
import Cdn from '@helpers/Cdn';

type Props = {
  product: ItemType;
}

export function ProductCart({ product }: Props) {
  const [countProduct, setCountProduct] = useState<string>(product.amount.toString());
  const [isMessageErrorShown, setIsMessageErrorShown] = useState(false);
  const [messageError, setMessageError] = useState<string | undefined>(undefined);
  const { addItem, removeItem, loading } = useShoppingCart();

  const { user } = useAuth();
  const nameIndustry = user?.getNameIndustryStore();
    const urlImage = Cdn.getProductImageUrl(product.code, nameIndustry || "");

  async function remove(ean: string) {
    await removeItem(ean);
  }

  async function addToCart(
    item: ItemType,
    quantity: string,
    actualQuantity: string,
    isInput: boolean = false
  ) {
    item = { ...item }
    item.amount = parseInt(quantity);
    if (
      quantity === "0" ||
      parseInt(actualQuantity) + parseInt(quantity) <= 0
    ) {
      try {
        await remove(item.code);
      } catch (error: any) {
        setMessageError(error.message)
        setIsMessageErrorShown(true);
      }

      return;
    }

    try {
      await addItem(item, isInput);
    } catch (error: any) {
      setMessageError(error.message)
      setIsMessageErrorShown(true);
    }
  }

  function handleInputCount(item: any) {
    if (!isNaN(item)) {
      let newCount = item;

      if (newCount === "" || isNaN(parseInt(newCount))) {
        newCount = "0";
      }

      setCountProduct(`${parseInt(newCount)}`);
    }
  }

  function handleInputBlur() {
    if (countProduct === '0') {
      remove(product.product.code);
    }
    addToCart(product, countProduct, "0", true);
  }

  return (
    <View style={styles.card}>
      <View>
        <Image
          style={styles.image}
          source={{ uri: urlImage }}
          defaultSource={require("../../../assets/images/product_not_found.png")}
          resizeMode="contain"
          resizeMethod="scale"
        />
        {(product?.totalDiscount || 0) > 0 && (
          <Center
            borderRadius={4}
            bg="orange.500"
            _dark={{
              bg: "orange.500",
            }}
            position="absolute"
            top={2}
            right={2}
            p={1}
          >
            <Text fontSize={["2xs", "xs"]} fontWeight="700" color="muted.100">
              {product?.totalDiscount || 0}% OFF
            </Text>
          </Center>
        )}
      </View>
      <View style={styles.containerSide}>
        <View style={styles.containerTextProduct}>
          <Text style={styles.textProduct}>{product.product.description}</Text>
          <Text
            lineHeight={"md"}
            fontSize={["xs", "xs", "xs", "sm"]}
            color={"muted.800"}
            _dark={{
              color: "muted.400",
            }}
            fontStyle={"normal"}
            fontWeight={"normal"}
            selectable
          >
            {product.product.code}
          </Text>
        </View>
        <View style={styles.containerSideBottom}>
          <View style={styles.containerPriceProduct}>
            <View style={styles.priceRow}>
              {product.discount > 0 ? (
                <>
                  <Text
                    style={styles.textNetPriceProduct}
                    textDecorationLine="line-through"
                  >
                    R$ {helpers.currency(product.unitGrossPrice, 2)}
                  </Text>
                  <Text style={styles.textNetPriceProduct}>
                    R$ {helpers.currency(product.unitNetPrice, 2)}
                  </Text>
                </>
              ) : (
                <>
                  <Text style={styles.textNetPriceProduct}>
                    R$ {helpers.currency(product.unitNetPrice, 2)}
                  </Text>
                </>
              )}
            </View>
          </View>
          <View style={[styles.containerActionProduct]}>
            <IconButton
              icon={"delete"}
              color="#AAAAAA"
              size={RFValue(10)}
              onPress={() => remove(product.code)}
              rippleColor="rgba(229, 229, 229, 0.5)"
              style={styles.iconDelete}
            />
            <View style={styles.containerActionProductCount}>
              <TouchableOpacity
                style={[
                  styles.containerActionProductCountSquare,
                  { backgroundColor: "#DADADA" },
                  null,
                ]}
                activeOpacity={0.5}
                onPress={() => {
                  addToCart(product, "-1", countProduct);
                }}
                disabled={loading}
              >
                <Ionicons
                  name="remove-outline"
                  size={RFValue(8)}
                  color="#6F7490"
                  style={styles.iconCountSquare}
                />
              </TouchableOpacity>
              <PopoverMessageValidation
                accessibilityLabel="Mensagem de erro ao alterar quantidade"
                isOpen={isMessageErrorShown}
                onClose={() => {
                  setIsMessageErrorShown(false);
                  setMessageError(undefined);
                }}
                trigger={(triggerProps) => {
                  return (
                    <TextInput
                      style={styles.inputActionProductCount}
                      onChangeText={handleInputCount}
                      keyboardType="numeric"
                      value={countProduct}
                      textAlign={"right"}
                      onBlur={(e) => handleInputBlur()}
                      onKeyPress={(event) => {
                        if (event.nativeEvent.key !== "Enter") {
                          return;
                        }

                        handleInputBlur();
                      }}
                      {...triggerProps}
                    />
                  );
                }}
                message={messageError}
              />
              <TouchableOpacity
                style={[
                  styles.containerActionProductCountSquare,
                  { backgroundColor: "#74BF48" },
                ]}
                activeOpacity={0.5}
                onPress={() => {
                  addToCart(product, "1", countProduct);
                }}
                disabled={loading}
              >
                <Ionicons
                  name="add-outline"
                  size={RFValue(8)}
                  color="#FFFFFF"
                  style={styles.iconCountSquare}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
