import React, { useEffect, useState } from "react";
import { View, Text } from 'native-base';
import { Loading } from "../../components/Loading";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { useAuth } from "../../contexts/Auth";
import { useNavigation } from '@react-navigation/native';
import { useWholesalersStorage } from "@hooks/useWholesalersStorage";
import { UnexpectedError } from "@components/UnexpectedError";

export const LoadWholesaler = () => {
  const navigation = useNavigation();
  const { user } = useAuth();
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [error, setError] = useState(false);

  const industry = user?.getNameIndustryStore();
  const { loading: loadingCart, changeShippingOrder } = useShoppingCart()

  const { loading, wholesalers } = useWholesalersStorage();

  const handleActionButton = async () => {
    if (wholesalers.length <= 0) {
      setError(true);
      return false;
    }
    await changeShippingOrder(wholesalers);
    setLoadingScreen(false);
    navigation.reset({
      index: 0,
      routes: [
        {
          name: "Industry",
          params: { industry: industry },
        },
      ],
    });
  };

  useEffect(() => {
    if (!loading && !loadingCart && loadingScreen) {
      handleActionButton()
    }

  }, [loading, loadingCart])


  if (loading || loadingCart || loadingScreen) {
    return <Loading />;
  }

  if (error) {
    return <UnexpectedError consoleError={'Erro ao carrega distribuidores do cliente'} />;
  }

  return (
    <View>
      <Text>
        Carregado
      </Text>
    </View>
  );
};